<template>
  <v-row class="px-10" dense>
    <v-col cols="12" md="4">
      <v-text-field v-model="client.email" outlined type="email" label="Email" rounded dense autocomplete="off" />
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field v-model="client.password" @click:append="showPassword = !showPassword" autocomplete="off"
        :append-icon="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'" :type="text" outlined label="Senha" rounded
        dense />
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field v-model="password" @click:append="showPassword = !showPassword" autocomplete="off"
        :append-icon="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'" :type="showPassword ? 'text' : 'password'"
        :rules="[this.password == this.client.password || 'Senhas estão diferentes']" outlined label="Repetir senha"
        rounded dense />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    client: Object,
    password: String
  },
  data: () => ({
    showPassword: false,
  }),


}
</script>